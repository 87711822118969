@use "sass:map";
@import "commonstyle";
$darktheme-colors: ("bgcolor": #0D0F12,
	"background-color": #0C1214,
	"bordercolor": #3B424C,
	"theme-color": #959595,
	"commonbg-color":#0d0f12,
	"popup-bg-color":#171819);
$whitetheme-colors: ("bordercolor": $light-border,
	"bgcolor": #EDEDED,
	"color": $light-nav-font-color,
	"commonbgcolor":#F7F7F7,
	"panel-color":#69737A,
	"background-color": #353D4B,
	"popup-bg-color": #F7F7F7);

#ribbon ol.breadcrumb {
	font-size: 13px;
	font-weight: 600;
}

.green-label {
	color: $commonbase-color !important;
}

.form-control.ng-invalid.is-invalid {
	border-color: $invalid-bordercolor !important;
}

.dt-toolbar {
	display: none !important;
}
.borderHead-align {
	.dt-toolbar {
		background: transparent;
		display: block !important;
		border-bottom: none !important;
		z-index: 999;
		height: 1px;
		.dataTables_length {
			visibility: hidden;
		}
	}
} 

table {
	width: 100%;
	background-color: inherit !important;
	color: $primary-color;
	border-collapse: collapse;
}

.demo {
	label {
		input[type=checkbox].checkbox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		input[type=radio].radiobox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		&:hover {
			input[type=checkbox].checkbox+span {
				&:before {
					border-color: #E77317;
				}
			}
		}

		margin-bottom: 4 !important;
	}

	.checkbox {
		i {
			border-color: $subtext-color;
		}

		padding-left: 22px;
	}

	.radio {
		padding-left: 22px;
	}

	#demo-setting+form {
		display: none;
	}

	position: absolute;
	top: 5px;
	right: 0;
	width: 160px;
	z-index: 902;
	background: #F1DA91;

	span {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		height: 30px;
		width: 30px;
		border-radius: 5px 0 0 5px;
		background: #F1DA91;
		padding: 2px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;

		i {
			font-size: 17px;
		}

		&:hover {
			i {
				color: #333 !important;
			}
		}
	}

	form {
		section {
			margin: 0 !important;
		}
	}

	.btn {
		font-size: 13px !important;
		line-height: 1.5 !important;
		border-radius: 3px !important;
		padding: 3px 7px !important;
		height: auto !important;
	}
}

.demo.activate {
	right: 0 !important;
	box-shadow: -11px 12px 23px rgba(0, 0, 0, .2);
	padding: 5px 10px 10px;

	#demo-setting+form {
		display: block;
	}

	span {
		i {
			&:before {
				content: "\f00d";
			}
		}

		left: -30px;
	}
}

.demo-liveupdate-1 {
	font-size: 12px !important;
	position: absolute !important;
	left: 33px;
	top: 15px;
	display: block;
	z-index: 2;
	font-weight: 700;
	padding: 1px 61px 3px 7px;
}

.demo-btns {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-bottom: 7px;
	}
}

.demo-icon-font {
	font-size: 14px;
	margin-bottom: 6px;
	padding-top: 4px;
	padding-bottom: 4px;

	&:hover {
		background-color: rgba(86, 61, 124, .1);
		text-decoration: none;
	}
}

.demo-modal {
	width: auto;
	max-width: 600px;
	z-index: 1;
}

// .demo-red {
// 	color: #DF413E;
// }
// .demo-orange {
// 	color: #DAA03E;
// }
.demo-border {
	border: 1px solid rgba(0, 0, 0, .2);
}

.demo-vertical-uislider {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	div {
		height: 200px;
		margin: 0 0 0 10px;
		display: inline-block;
	}

	&:first-child {
		margin-left: 15px;
	}
}

.toggle-demo {
	position: absolute;
	top: 10px;
	right: -8px;
	display: block;
	width: 154px;
	height: auto;
	z-index: 99;
	opacity: .9;

	&:hover {
		opacity: 1;
	}

	.btn-group {
		width: 100px;
		float: left;
	}

	span {
		display: inline-block;
		float: left;
		margin-right: 5px;
		line-height: 21px;
		color: #757575;
	}
}

#header {
	.menu-item {
		margin: 0px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 60px;
		cursor: pointer;
	}

	.menu-item_pading {
		padding: 0 10px;
	}

	.menu-left-item {
		padding: 0 10px;
	}

	.company-title {
		font: normal normal bold 12px/19px Open Sans;
	}

	.company-name {
		font: normal normal normal 14px/19px Open Sans;
	}

	#fullscreen {
		.svg-icon {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 60px !important;
			height: 45px !important;
		}
	}

	.notification-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	#activity.activity-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	.collapseMenu {
		.collaspe-menu-svg {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 40px !important;
			height: 30px !important;
		}

		.collaspe-menu-sub-svg {
			background-repeat: no-repeat;
			width: 45px !important;
			height: 45px !important;
		}
	}

	.header-btn {
		&:hover {
			border-color: $commonbase-color !important;
			background: $commonbase-color !important;
		}
	}

	.dropdown-icon-menu {
		ul {
			li {
				.btn {
					&:hover {
						border-color: $commonbase-color !important;
						background: $commonbase-color !important;
					}
				}
			}
		}
	}
}


.knobs-demo {
	div {
		display: inline-block;
		margin-left: 30px;
	}

	&:first-child {
		margin-left: 0;
	}
}


.widget-box {
	background-color: $primary-color;
	box-shadow: 10px 10px 45px 0 rgb(19 46 72 / 20%);
	border-radius: 5px;
	overflow: hidden;
	padding: 20px;

	.title-box {
		.title {
			.ed-icon {
				transform: translateY(-50%) scale(0.8);
				transform-origin: left center;
			}

			.title-lbl {
				font-size: 18px;
				line-height: 18px;
				padding-left: 35px;

				&:before {
					left: 25px;
				}
			}

			.title-lbl.no-icon {
				padding-left: 0;

				&:before {
					display: none;
				}
			}
		}
	}

	.icon-box {
		width: 70px;
		min-width: 70px;
		height: 70px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.number {
			font-size: 30px;
		}

		.ed-icon {
			width: 30px;
			height: 30px;
		}
	}

	h3 {
		font-size: 24px;
		line-height: 24px;
	}

	p {
		font-size: 18px;
		line-height: 20px;
	}
}

.activity.ajax-dropdown {
	&:first-child {
		margin: 0;
		padding: 0;
	}
}

.activity {
	.ajax-notifications {
		border-bottom: none;
		border-top: none;
	}
}


.fixed-navigation {
	.nav-demo-btn {
		display: none !important;
	}
}

.minified {

	.nav-demo-btn,
	.ac_ConnectionSection {
		display: none !important;
	}
}

.smart-rtl {
	.demo {
		.margin-right-5 {
			margin-right: 0 !important;
		}
	}
}

.listFinal {
	font-size: 16px;
	padding: 5px;

	&::before {
		content: ">";
		font-size: 20px;
		padding: 5px;
	}
}

.minified {

	#clearCache_text,
	.module-name {
		display: none;
	}

	#help_text {
		display: none;
	}

	.userlabel {
		display: none !important;
	}

	.body-image {
		width: 40px !important;
		height: 40px !important;
		margin: -15px 0px 10px -45px;
	}

	.footer-menu {
		padding: 12px 0px !important;
	}

	.tutorial-popup {
		width: 43px !important;
		padding: 3px 12px;
	}

	.module {
		padding: 5px 5px 5px 10px !important;

		.module-img {
			width: 26px !important;
		}
	}

	.chatIcon {
		margin-left: 8px;
	}

	#clearCache {
		.iom_btn {
			padding: 3px 13px !important;
		}
	}

	.ebill-icon {
		width: 26px !important;
	}

	.hrColor {
		display: none;
	}

	.ebill-nav {
		padding: 5px 8px !important;
	}

	#left-panel {
		padding-top: 0px !important;
	}
}


.header-dropdown-list {
	li {
		.dropdown-toggle {
			margin-top: 6px;
		}
	}
}




.dash-icon {
	background-image: url(/assets/img/application/dashboard.svg);
}

.purchase-icon {
	background-image: url(/assets/img/application/purchase.svg);
}

.inv-icon {
	background-image: url(/assets/img/application/invoice.svg);
}

.file-icon {
	background-image: url(/assets/img/application/filemanager.svg);
}

.vmi-icon {
	background-image: url(/assets/img/application/vmi.svg);
}

.contract-icon {
	background-image: url(/assets/img/application/contract.svg);
}

.edi-icon {
	background-image: url(/assets/img/application/edi.svg);
}

.header-dropdown-list {
	.dropdown-menu {
		li {
			a {
				border-radius: 0 !important;
				font-size: 14px;
			}
		}

		padding: 4px;
		overflow: auto;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0px;
		}

		border-radius: 10px;
	}
}

.nav-demo-btn {
	display: none
}

body:not(.menu-on-top) .nav-demo-btn {
	display: block;
	padding: 6px 5px;
	margin: 5px 10px;
	width: auto;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	font-size: 12px;
	white-space: normal
}

.ajax-dropdown-app>:first-child {
	margin: 0 0 3px;
	padding: 0 0 9px;
}

// .page-footer {
//     position: fixed;
//     z-index: 1000;
//     border-top: transparent;
// }
.hidden-menu .page-footer {
	padding-left: 20px;
}

.main-nav {
	text-align: right;
	font: normal normal normal 16px/22px Open Sans;
	letter-spacing: 0px;
	color: $base-color;
	opacity: 1;
	padding-top: 11px;
}

.setCircleactive {
	color: #00eba9;
	font-size: 10px !important;
}

.setCirclehold {
	color: #3400eb;
	font-size: 10px !important;
}

.setCirclerej {
	color: $invalid-color;
	font-size: 10px !important;
}

.main-container {
	display: flex;

	flex : {
		direction: column;
		grow: 1;
	}

	padding : {
		left: 0;
		right: 10px;
	}
}


#content {
	background: $table-bgcolor !important;
}

.footerTextColor {
	color: #8B91A0 !important;
}

.theme-header-color {
	color: $base-color !important;
}


.header-menu {
	font: normal normal normal 14px/19px Open Sans;
}

.notification-body>li {
	border: 1px solid #e3e3e3 !important;
}

.flag.flag-en {
	background-position: -144px -154px;
}

#left-panel {
	position: absolute;
	top: 51px;
	z-index: 904;
	padding-top: 10px;

	nav {
		ul {
			li.active {
				>a {
					&:before {
						right: -19px;
						content: none !important;
					}
				}
			}
		}
	}
}

.font-size-14 {
	font-size: 14px !important;
}

.notification-body {
	.subject {
		color: $subtext-color !important;
	}
}



.iombackground {
	background : {
		repeat: no-repeat;
		position: center;
	}

	// max-height: 100vh;
	height: 100vh !important;


	background: #C04848;
	/* fallback for old browsers */
	background: linear-gradient(rgb(102, 102, 102, 0.9), rgb(102, 102, 102, 0.9)),
	url(/assets/img/register.png);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(rgb(102, 102, 102, 0.9), rgb(102, 102, 102, 0.9)),
	url(/assets/img/register.png);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-repeat: no-repeat;
}

// .userAccountSvg {
// 	background : {
// 		repeat: no-repeat;
// 		position: center;
// 	}

// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/user.png");
// }

// .toolsvg{
// 	background : {
// 		repeat: no-repeat;
// 		position: center;
// 	}
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/tools.png");
// }
// .support-svg{
// 	background : {
// 		repeat: no-repeat;
// 		position: center;
// 	}

// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/support.png");	
// }
// .conection-svg{
// 	background : {
// 		repeat: no-repeat;
// 		position: center;
// 	}

// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/conections.png");
// }
// .data-svg{
// 	background : {
// 		repeat: no-repeat;
// 		position: center;
// 	}	
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/data2x.png");
// }




.scroll-content::-webkit-scrollbar {
	display: none;
}

.scroll-content {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.mobile-view-activated::-webkit-scrollbar {
	display: none;
}

.mobile-view-activated {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.MessageBoxButtonSection button {
	border-radius: 20px;
	background: transparent;
	border: 2px solid $commonbase-color !important;
	font-size: 13px;
	padding: 3px 15px;
	font-weight: 400 !important;
}

.MessageBoxButtonSection button:hover {
	border-radius: 20px;
	background: $commonbase-color !important;
	border: 2px solid $commonbase-color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $fontcommon-color !important;
	font-weight: 400 !important;
}

.minified nav>ul>li>a>.menu-item-parent {
	top: -1px;
	height: 41px;
}

.ui-datepicker {
	border-radius: 5px !important;

	select.ui-datepicker-month {
		margin: 0 2px;
		width: 47%;
	}

	select.ui-datepicker-year {
		margin: 0 2px;
		width: 41%;
	}
}

.ui-datepicker {
	td {
		.ui-state-default {
			color: $primary-color;
		}
	}

	.ui-datepicker-next {
		span {
			background: none !important;
		}
	}

	.ui-datepicker-prev {
		span {
			background: none !important;
		}
	}
}

.ui-datepicker-prev {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-next {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-prev {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f053";
	}
}

.ui-datepicker-next {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f054";
	}

}

.ui-datepicker {
	width: 19em;
	padding: 0.2em 0.2em 0;
	display: none;
}

// .ui-datepicker {
// 	select.ui-datepicker-month {
// 		margin: 0 2px;
// 		width: 47%;
// 	}

// 	select.ui-datepicker-year {
// 		margin: 0 2px;
// 		width: 47%;
// 	}
// }

// .ui-datepicker {	

// 	.ui-datepicker-next {
// 		span {
// 			background: none !important;
// 		}
// 	}

// 	.ui-datepicker-prev {
// 		span {
// 			background: none !important;
// 		}
// 	}
// }

// .ui-datepicker-prev {
// 	span {
// 		background-image: none !important;
// 	}
// }

// .ui-datepicker-next {
// 	span {
// 		background-image: none !important;
// 	}
// }

// .ui-datepicker-prev {
// 	&:before {
// 		font-family: FontAwesome;
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		bottom: 0;
// 		left: 0;
// 		display: flex;
// 		font-weight: normal;
// 		align-items: center;
// 		justify-content: center;
// 		background:  $datepicker-color;
// 		z-index: 9;
// 		padding-top: 4px;
// 		content: "\f053";
// 	}
// }
// .ui-datepicker-next {
// 	&:before {
// 		font-family: FontAwesome;
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		bottom: 0;
// 		left: 0;
// 		display: flex;
// 		font-weight: normal;
// 		align-items: center;
// 		justify-content: center;
// 		background: $datepicker-color;
// 		z-index: 9;
// 		padding-top: 4px;
// 		content: "\f054";
// 	}
// }
nav ul ul {
	padding: 0px;

	li {
		padding: 6px;
	}
}

nav ul ul li>a:hover {
	background: $commonbase-color !important;
}

nav ul ul li>a:hover {
	background: #666 !important;
}

nav ul b .fa-plus-square-o:before {
	content: "\f107" !important;
}

nav ul b .fa-minus-square-o:before {
	content: "\f106" !important;
}

.table thead:first-child tr:first-child th {
	border-bottom: none !important;
}

.btn-primary {
	background: transparent !important;
	border: 2px solid $commonbase-color;
	padding: 3px 15px;
	border-radius: 15px;
	font-size: 13px;

	&:hover {
		background: $commonbase-color !important;
		color: $common-color !important;
		border: 2px solid $commonbase-color;
	}

	&:focus {
		box-shadow: none;
	}
}

.custom-filter {
	padding: 3px 23px !important;
}

.commonHead {
	font-size: 14px;
	border: unset !important;

	h2 {
		margin-left: 3px !important;
		font-size: 16px;
	}
}

.form-control {
	height: 40px !important;
	border-radius: 6px !important;
	font-size: 14px;
}

.iom-button {
	border-radius: 20px;
	background: transparent;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 7px 28px;

	&:hover {
		background: $commonbase-color !important;
		color: $fontcommon-color !important;
	}
}

.btn-primary {
	&:hover {
		background: $commonbase-color !important;
		color: $fontcommon-color !important;
	}
}

@media (max-width: 979px) {
	.page-footer {
		padding: 5px 14px 0;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.flag+span {
		max-width: 100% !important;
	}
}

@media (max-width: 576px) {
	.joyride-step__container {
		width: auto;
	}

	textarea.in {
		width: auto;
	}

}

.minified {
	.profile-image-box {
		display: none;
	}

	ul.navList {
		margin-top: -25px !important;
	}

	.description,
	.ac_ConnectionSection,
	.company-label {
		display: none;
	}

	.helpSection {
		height: 40px !important;
	}

	.chatIcon img {
		margin-left: -8px;
	}
}

.tranhoverTable tbody tr {
	td.text-left.green-text {
		color: $commonbase-color !important;

		a {
			color: $commonbase-color !important;
		}
	}
}

.tranhoverTable tbody tr.selected {
	td.text-left.green-text {
		color: #171717 !important;

		a {
			color: #171717 !important;
		}
	}
}

table.dataTable {
	clear: both;
	// margin-top: 6px !important;
	margin-bottom: 6px !important;
	max-width: none !important;
	border-collapse: separate !important;
}

table.positionTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.positionTable thead tr th:last-child {
	border-radius: 0px 5px 0px 0px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 5px !important;
	border-top-left-radius: 5px !important;
}

.dt-toolbar-footer {
	padding: 5px 0px;
}

// .slider-turn, .search-contract-next, .search-contract-end {
// 	color: $fontcommon-color !important;
// }
.slider-container {
	margin-left: 25px;
}

.exportBtn-help h1,
.exportBtn-help-table h1 {
	font-size: 18px;
	font-weight: 700;
	margin: 15px 25px;
	overflow: hidden;
}

.searchTableContacts.exportBtn-help:before {
	content: '';
	position: absolute;
	right: -10px;
	top: 48px;
	border-style: solid;
	border-width: 14px 14px 14px 0;
	border-top-color: rgba(0, 0, 0, 0);

	border-bottom-color: rgba(0, 0, 0, 0);
	border-left-color: rgba(0, 0, 0, 0);
	transform: rotateY(180deg);
}

.joyride-step__holder {
	border-radius: 15px;
	z-index: 2000;
}

.joyride-step__container {
	border-radius: 15px;
	// min-width: 360px !important;
	width: 438px !important;
	max-height: 300px !important;

	.joyride-step__body {
		// height: 300px !important;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 5px !important;
			height: 5px !important;
		}

		&::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #CCC;
			border-radius: 5px;
		}
	}
}

.joyride-button {
	border-radius: 15px;
	background: transparent !important;
	border: 2px solid $commonbase-color !important;
	padding: 3px 15px !important;
	border-radius: 15px !important;
	font-size: 13px !important;
}

.joyride-step__close {
	text-align: right;
	letter-spacing: 0px;
	color: $commonbase-color !important;
	opacity: 1;
	font-size: 18px;
	font-weight: bold;

	svg {
		display: none;
	}

	&:before {
		content: "\f00d";
		font-family: 'Font Awesome 5 Free';
		left: 5px;
		position: absolute;
		top: -10px;
		font-size: 18px;
		font-weight: 900;
		text-align: right;
	}
}

.joyride-step__footer {
	justify-content: end !important;
	flex-direction: row-reverse !important;
	padding-top: 10px !important;
}

textarea.in {
	display: none;
	width: 400px !important;
	height: 180px !important;
	font-size: 13px;
	color: $primary-color;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid $header-bgcolor !important;
	border-radius: 5px !important;

	&::-webkit-scrollbar {
		width: 0px;
	}
}

.tour-title-input {
	border-radius: 5px !important;
	height: 35px;
	width: 100%;
	color: $primary-color;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid $header-bgcolor !important;
	font-size: 13px;
	margin-top: 15px;
}

.joyride-step__counter-container {
	margin-right: 10px !important;
	//     margin-left: 5px !important;
}

.backdrop-container {
	z-index: 1100 !important;
}

.joyride-step__holder {
	z-index: 1100 !important;
}

.btn-save {
	display: none;
}

.btn-primary {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
			}
		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
				}
			}
		}
	}
}

.btn-primary {
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				border-color: $commonbase-color !important;
			}
		}
	}
}

.idTooltip {
	text-align: center;
	z-index: 99999;
	position: absolute;
	width: 150px;
	height: 30px;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		z-index: 99999;
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
	}
}

// table.dataTable tbody tr.selected:hover {
// 	background-color: $commonbase-color !important;
// 	border-color: $light-border;
// }

table.dataTable tbody tr:hover {
	cursor: pointer;
}

// table.dataTable tbody tr.selected {
// 	background-color: $commonbase-color !important;
// 	border-color: $light-border;
// 	color: $fontcommon-color !important;
// }

/*------------------------- Drop-down Menu -------------------------*/
.dropdown-list {
	margin-top: 4px;
	min-width: 270px;
	font-family: Open Sans, Arial, Helvetica, Sans-Serif;
	position: absolute;
	z-index: 1000;

	.dropdown-list-items-contents {
		display: block;
		max-height: calc(100vh - 120px);
		overflow: overlay;
		max-width: 320px;
		border-radius: 10px;
	}

	.dropdown-list-row {
		// color: #3b424c;
		padding: 15px 0px;
		display: flex;
		flex-wrap: wrap;

		.list-group-item {
			background-color: transparent !important;
			border: none !important;
			display: flex !important;
			flex-direction: row;

			div {
				padding: 0px 2px;
				display: flex;
				width: 25px !important;

				i {
					margin: auto;
				}
			}
		}
	}

	.dropdown-list-box {
		width: 33.3333%;
		text-align: center;
		padding: 30px 20px 0;
		font-weight: 500;
	}

	.border-bootm-green {
		border-bottom: 1px solid $commonbase-color;
	}
}

.billerFormSection {
	.input-group-append {
		position: absolute !important;
		right: 0 !important;
		top: 6px !important;

		.input-group-text {
			border: unset;
			background-color: unset;
			font-size: 14px;
		}
	}

	.companySelect {
		height: 50px;

		.ng-select-container {
			height: 40px;
		}

		.ng-dropdown-panel.ng-select-bottom {
			margin-top: -10px !important;
			overflow: auto;
		}
	}
}

.iom_btn,
.ebill_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	color: $primary-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 20px;
	font-weight: 400;

	&:hover {
		border-color: $commonbase-color !important;
		background: $commonbase-color !important;
		color: $common-color !important;
	}
}

.companySelect {
	.ng-select-container {
		background: $content-bg-color !important;
		border: 1px solid #6666;

		.ng-placeholder {
			font-size: 13px;
		}
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		background-color: #666 !important;
		border: 1px solid $header-bgcolor !important;
		font-size: 13px !important;

	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: $content-bg-color !important;
		color: $primary-color;
		font-size: 13px !important;
		// border: 1px solid #6666 !important;	
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
		background-color: $content-bg-color !important;
	}

	.requiredSector {
		color: $invalid-bordercolor;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
		color: $commonbase-color !important;
		font-size: 13px !important;
	}
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background-color: transparent !important;
	border: 1px solid #666;
	border-bottom: unset;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
	background-color: transparent !important;
	border: unset;
}

.ebillForm {
	formly-field-select {
		select {
			option {
				background-color: $content-bg-color !important;
			}
		}
	}

	.commmonWrap {
		formly-group {
			width: 100% !important;
		}
	}
}

// .tokenForm {
select {
	option {
		background-color: $content-bg-color !important;
	}
}

// }

.commmonWrap {
	formly-group {
		width: 100% !important;
	}
}

.requiredSection {
	.ng-select-container {
		border-color: $invalid-bordercolor !important;
	}
}

.requiredSector {
	color: $invalid-bordercolor !important;
}

.billerFormSection {
	formly-wrapper-form-field {
		div {
			font-size: 13px !important;
		}
	}
}

.biller_tabSection {
	.nav-item {
		font-size: 14px !important;
	}
}

.chatIcon {
	width: 30px;
	height: 30px;
	margin-right: 10px;

}

.refreshStatus {
	margin-right: 14px;
	float: right;
	margin-top: -5px;
}

.refreshStatus-popup {
	margin-right: 14px;
	float: right;
	margin-top: -5px;
}

/*------------------------- Drop-down Menu -------------------------*/

// *********************Ebill css********************************************

.wizard .plusBtn {
	position: absolute;
	right: 20px;
	cursor: pointer;
	top: 13px;
}

.wizardCircleWidth {
	width: 20%;
	flex: 1 0 20% !important;
}

.wizardTextWidth {
	width: 75%;
	flex: 1 0 75% !important;
	padding: 0px 10px;
	display: flex;
	align-items: flex-start !important;
}

.wizardDiv {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	font-size: 24px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
}

b.badges {
	position: absolute;
	cursor: pointer;
	font-size: 10px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	padding: 2px 4px 3px;
	text-align: center;
	line-height: normal;
	content: "";
	top: -1px;
	right: -1px;
	transform: translate(25%, -25%);
	width: 25px;
	height: 25px;
	color: $secondaryFocus;
	background-color: $commonbase-color;
	font-weight: 800;
	display: flex;
	justify-content: center;
	align-items: center;
}

.accordianXpand {
	transition: all 0.2s ease-in-out 0s !important;
}

.wizard#accordionExpandWizard {
	border-radius: 10px;
	color: $header-bgcolor;
	padding: 20px 20px 0px;
}

.wizardCircleWidth {
	width: 20%;
	flex: 1 0 20% !important;
}

.line {
	width: 5%;
	z-index: 1;
	position: relative;
}

.common_wizardItemContainer .common_wizardItems {
	width: 35% !important;
	cursor: pointer;
}

.wizard-items {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;
}

.green-border {
	border: 1px solid $commonbase-color !important;
}

#wizard .wizard-container {
	display: flex;
	flex-direction: row;
}

#wizard {
	border-radius: 15px;
	padding: 10px 20px;
}

.wizardTextWidth {
	width: 95%;
	flex: 1 0 75% !important;
	padding: 0px 10px;
	display: flex;
	align-items: flex-start !important;
}

.form-group .form-control {
	position: relative;
	float: left;
	width: 100%;
	margin-bottom: 0;
	font-size: 12px;
	height: 40px;
}

.wizard-billeritem {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;
}

.line-1 {
	height: 1px;
	width: 100%;
	z-index: 200;
	position: relative;
	top: 50% !important;
}

.billerFormSection formly-wrapper-form-field div {
	font-size: 13px !important;
}

.biller_tabSection .nav-item {
	font-size: 14px !important;
}

.form-group {
	.form-control {
		position: relative;
		// z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		font-size: 12px;
		height: 40px;
	}

	textarea {
		&.form-control {
			height: unset !important;
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
		}
	}
}

.communicationPanel {
	display: block !important;
}

.billerFormSection formly-wrapper-form-field div {
	font-size: 14px !important;
}

.includePdf formly-field-radio div .custom-radio {
	padding-left: 2.5rem;
}

.includePdf formly-field-radio div {
	display: flex;
}

.ebillForm {
	formly-field-select {
		select {
			option {
				background-color: $content-bg-color !important;
			}
		}
	}

	.commmonWrap {
		formly-group {
			width: 100% !important;
		}
	}
}

.commmonWrap {
	formly-group {
		width: 100% !important;
	}
}

table.positionTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.dataTable thead>tr>th.sorting_disabled {
	padding-right: 9px !important;
}

.bg-color-green {
	background-color: $commonbase-color !important;
}

.dataTable tbody tr td {
	font-size: 13px;
}

.modal-content {
	border-radius: 10px;
}


// *********************end Ebill css********************************************

.nav_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	color: $primary-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 20px;
	font-weight: 400;

	&:hover {
		border-color: $commonbase-color !important;
		background: $commonbase-color !important;
		color: $common-color !important;
	}
}

ul.invoice-tab>li.active>a {
	border: 1px solid $commonbase-color !important;
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
	box-shadow: 0 -2px 0 $commonbase-color !important;
}

.table {
	tbody {
		tr {
			td {
				padding: 7px;
				line-height: 1.42857143;
				vertical-align: top;
			}
		}
	}
}

.invoice-tab-header {
	.nav-tabs {
		>li {
			text-align: center;

			>a {
				margin: auto;
			}
		}
	}
}

.tooltipA {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltipA .tooltiptext {
	visibility: hidden;
	min-width: 180px;
	text-align: center;
	padding: 5px 10px;
	border-radius: 6px;
	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 100;
	top: -5px;
	right: 40%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
	visibility: visible;
}

.error-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 30px;

	div.error-div-div {
		width: 300px;
		width: 300px;
		height: 200px;
		border-radius: 8px;
		padding: 5px;
	}
}

tr.selected {
	span.green-label {
		color: $secondaryFocus !important;
	}
}

.scroll-tbs {
	height: 170px !important;
	overflow-y: auto !important;

	margin : {
		top: 10px;
		left: -3px;
	}

	// -ms-overflow-style: none;
	// scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	&::-webkit-scrollbar-track {
		// border: 1px solid $content-bg-color;
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #CCC;
		// border: 1px solid $content-bg-color;
		border-radius: 5px;
	}

	table.dataTable {
		tbody {
			tr.selected {
				background-color: $commonbase-color !important;
				border-color: $light-border;
				color: $common-color !important;

				&:hover {
					background-color: $commonbase-color !important;
					border-color: $light-border;
				}
			}
		}
	}
}

.dividerOr {
	.alignOr {
		display: flex !important;
		justify-content: center !important;
		margin: 0px !important;
		padding-top: 10px !important;
		font-size: 14px;
	}
}

.searchRecipientForm {
	formly-field {
		label {
			font-size: 13px;
		}

		formly-field-input {
			input {
				border-radius: 10px !important;
			}
		}

		formly-validation-message {
			font-size: 12px;
		}

	}
}

.filter-top {
	font-size: 16px !important;
	letter-spacing: 0;
	font-weight: 400;
	font-family: Open Sans, Arial, Helvetica, Sans-Serif;
}

.main-title {
	font-size: 16px !important;
	font-family: Open Sans, Arial, Helvetica, sans-serif;
}

.commonCl {
	flex: 1 0 20%;
	margin: 0px 6px;
}

.app-list {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}

.app-tut {
	width: 220px;
	height: 220px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px;
	cursor: pointer;

	.app-inner-tut {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&:first-child {
		margin-left: 0px !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.app-img {
	width: 100px;
	height: 100px;

	img {
		width: 100px;
		height: 100px;
	}
}

.app-title {
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
}

textarea.modal-in {
	min-width: 400px;
	height: 300px;
	font-size: 13px;
	background-clip: padding-box;
	border: 1px solid #666666 !important;
	border-radius: 10px !important;
}

textarea.form-control {
	height: unset !important;
}

.closeModal {
	a {
		cursor: pointer;
	}
}


.inner-divs {
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.snd-rcv {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	// margin-bottom: 20px;
	cursor: pointer;
	white-space: nowrap;

	&:first-child {
		margin-left: 0px !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.ordr-invc {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	// margin-bottom: 20px;
	white-space: nowrap;

	&:first-child {
		margin-left: 0px !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.show-contacts-div {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	cursor: pointer;

}

.code-div {
	height: auto;
	margin: 20px 0 0 0;
	// cursor: pointer;
}

.invite-form-div {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 20px;
	cursor: pointer;
	white-space: nowrap;
}

.snd-rcv-div {
	border-radius: 10px;
	width: 250px;
	height: 220px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.snd-rcv-img {
		width: 100px;
		height: 100px;

		img {
			width: 100px;
			height: 100px;
		}
	}

	.snd-rcv-title {
		margin-top: 10px;
		font-size: 26px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
	}
}

.ordr-invc-div.nohover {
	cursor: no-drop;
}

.ordr-invc-div {
	border-radius: 10px;
	width: 210px;
	height: 220px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid $commonbase-color;

	&:not(.nohover) {
		cursor: pointer;
	}

	.ordr-invc-img {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100px;
			height: 100px;
		}
	}

	.ordr-invc-title {
		display: flex;
		margin-top: 10px;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		word-wrap: break-word;
		// width: 150px;
		overflow: auto;
		white-space: pre-wrap;
		// height: 80px;
		align-items: center;
		justify-content: center;
	}
}

.nxt-btn,
.prev-btn {
	font-size: 16px !important;
}

.direction-title {
	text-transform: uppercase;
}

.create-contact {
	.formly-group {
		margin-bottom: 13px !important;
	}
}

@media screen and (max-width: 767px) {
	.table-responsive {
		border: none !important;
	}
}

.tableEmail a {
	font-weight: 400;
}

.dt-toolbar {
	.dataTables_filter {
		display: none !important;
	}

	.dataTables_length {
		display: block !important;
	}
}


#overlayModal {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	z-index: 1060;
}
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/

.smart-style-1 {

	// .profile-pic{
	// 	text-align: left !important;
	// 	position: absolute !important;
	// 	top: 36px;
	// 
	.searchTableContacts.exportBtn-help:before,
	.searchTable.exportBtn-help:before {
		border-right-color: $content-bg-color;
	}

	.exportBtn-help h1,
	.exportBtn-help-table h1 {
		color: $primary-color !important
	}

	.searchTableContacts.exportBtn-help,
	.searchTable.exportBtn-help {
		background-color: $content-bg-color !important;
	}

	body {
		background-color: $table-bgcolor !important;
	}

	#ribbon .breadcrumb li:last-child {
		color: $fontcommon-color !important;
	}

	.breadcrumb>li+li:before {
		color: $fontcommon-color;
	}

	#left-panel {
		nav {
			ul {
				li {
					border-bottom: 1px solid $commonbase-color;

					&:hover {
						background: $commonbase-color !important;
						color: $fontcommon-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						border: 1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;
					}
				}

				li.active {
					background-color: $commonbase-color !important;

					// margin-right: -1px !important;
					a {
						color: #202427 !important;
					}

					ul {
						li {
							color: #202427 !important;

							&:hover {

								a {
									color: #202427 !important;
									background: $commonbase-color !important;
								}
							}

							a {
								color: $primary-color !important;
							}

						}

						li.active {
							a {
								color: #202427 !important;
							}
						}
					}
				}

				li.open {
					li:hover {
						a {
							color: #202427 !important;
						}
					}
				}
			}
		}
	}


	#ribbon {
		.breadcrumb {
			li {
				&:last-child {
					color: $primary-color !important;
				}

				color: $panel-color;
			}
		}
	}

	.theme-header-text-color {
		color: $primary-color !important;
	}

	.theme-subtext-color {
		color: map.get($darktheme-colors, "theme-color") !important;
	}

	#content {
		background: $content-bg-color !important;
	}


	.theme-icon {
		color: $primary-color !important;
	}

	.ajax-dropdown {
		border: 1px solid #313840 !important;
	}

	.notification-body {
		li {
			border: 1px solid #252b31 !important;
		}
	}

	.dropdown-menu {
		background-color: map.get($darktheme-colors, "background-color");
		border: 1px solid map.get($darktheme-colors, "bordercolor");

		li {
			a {
				color: $primary-color;
			}
		}
	}

	.header-dropdown-list {
		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: $table-bgcolor !important;
					}
				}
			}

			.active {
				a {
					background-color: $table-bgcolor !important;
				}
			}
		}
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/Path 222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				// filter: brightness(0) invert(1);
			}

			&:hover {
				background-color: map.get($darktheme-colors, "bgcolor");
			}
		}

		.header-btn {
			background-color: $table-bgcolor;
		}

		.header-btn-list {
			a {
				background-color: $table-bgcolor !important;
			}
		}

		.menu-item {

			&:hover {
				background-color: #666666;
			}

		}

		.menu-item.no-hover {
			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.menu-left-item {
			// border-left: 1px solid map.get($darktheme-colors, "bgcolor");
			border-left: unset !important;
		}
	}


	.jarviswidget-color-darken {
		header {
			background: map.get($darktheme-colors, "popup-bg-color") !important;
			border: none;
		}
	}


	.border {
		border: 1px solid $header-bgcolor !important;
		border-radius: 10px;
	}

	// .menuSelected {
	// 	background-color: $header-bgcolor !important;
	// }

	.jarviswidget {
		>div {
			border: none;
		}
	}

	nav ul li a {
		color: $primary-color !important;
		font-size: 16px !important;

		&:focus,
		&:hover {
			color: #202427 !important;
			background: $commonbase-color !important;
			color: #202427 !important;
		}
	}

	nav ul {
		li.open {
			a {
				color: $primary-color !important;
				font-weight: 400;
				;
			}

			&:focus,
			&:hover {
				color: $primary-color !important;
				background: $commonbase-color !important;
			}
		}

		li.open a b {
			color: $primary-color !important;
		}

		li.open.active a b {
			color: #202427 !important;
		}

		li.open:hover>a {
			color: #202427 !important;
		}

		li.open:hover>a b {
			color: #202427 !important;
		}
	}

	nav ul ul {
		li {
			a {
				color: $primary-color;
				font-size: 14px !important;
				text-shadow: none !important;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $commonbase-color !important;
				}
			}
		}
	}

	.model-7 .checkbox label:after {
		background: $fontcommon-color !important;
	}

	.editButton {
		font-size: 37px;
		text-align: center;
		font: normal normal normal 30px/41px Open Sans;
		letter-spacing: -0.3px;
		background-color: $content-bg-color;
		border: 2px solid $commonbase-color;
		color: #202528;
		border-radius: 19px;
		font-size: 13px;
		top: 55px;
		position: relative;
		float: right;
		width: 155px;
		height: 34px;
		right: 217px;

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.modalBtn {
		// background-color:$fontcommon-color !important;
		max-width: 150px;
		width: 110px;
		margin-top: 11px;
		color: $primary-color !important;
		border: 2px solid $commonbase-color;
		padding: 7px 0px;

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.profileEditTextBox {
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
	}

	.changePasswordBtn,
	.editProfileBtn {
		&:hover {
			border-color: $commonbase-color !important;
			background: $commonbase-color !important;
			color: $fontcommon-color !important;
		}
	}

	.ui-datepicker {
		background: $datepicker-color !important;

		.ui-datepicker-header {
			background-color: rgba(255, 255, 225, .15);
		}

		select.ui-datepicker-month {
			border-color: $datepicker-color;
			background: $datepicker-color;
		}

		select.ui-datepicker-year {
			border-color: $datepicker-color;
			background: $datepicker-color;
		}

		th {
			color: $primary-color !important;
		}

		td {
			.ui-state-default {
				color: $primary-color;
			}

			.ui-state-active {
				background: $tabsborder-color;
				color: $primary-color !important;
			}
		}
	}

	.ui-datepicker-header {
		a {
			color: $primary-color !important;
		}
	}

	.ui-datepicker-month {
		color: $primary-color !important;
	}

	.ui-datepicker-year {
		color: $primary-color !important;
	}

	.ui-datepicker-prev {
		&:before {
			background: $datepicker-color;
		}
	}

	.ui-datepicker-next {
		&:before {
			background: $datepicker-color;
		}
	}

	.commonHead {
		background-color: $content-bg-color !important;
		color: $fontbackground-color;
	}

	.jarviswidget>div {
		background-color: $content-bg-color !important;
		border: none;
	}

	.headData {
		background: #1e1e1e 0 0 no-repeat padding-box;
		border: 1px solid $header-bgcolor;
		margin-top: 10px;
		color: $primary-color;
	}

	.dt-toolbar-footer {
		background: none !important;
		font-size: none !important;
		overflow: hidden;
		padding: none !important;
		border-top: none !important;
		-ms-box-shadow: none !important;
		box-shadow: none !important;
		width: 100%;
	}

	table {
		margin-top: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;

		tbody tr {
			color: $primary-color;

			// border: 1px solid #707070 !important;
			td {
				// padding: 8px;
				line-height: 1.42857143;
				vertical-align: middle;
				// border-top: 1px solid #666666;
				border-color: $header-bgcolor;
			}
		}
	}

	.pagination>.active>a {
		background-color: $commonbase-color !important;
		border-color: $commonbase-color !important;
		color: $common-color !important;
		border-color: $header-bgcolor;
	}

	.pagination>li>a {
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: transparent !important;
		border-color: $header-bgcolor;

	}

	// .pagination>.active>a {
	// 	background-color: $commonbase-color !important;
	// 	border-color: $commonbase-color !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li>a {
	// 	background-color: #303030 !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:first-child>a,
	// .pagination>li:first-child>span {
	// 	margin-left: 0;
	// 	border-bottom-left-radius: 2px;
	// 	border-top-left-radius: 2px;
	// 	background-color: #303030 !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:last-child>a,
	// .pagination>li:last-child>span {
	// 	border-bottom-right-radius: 2px;
	// 	border-top-right-radius: 2px;
	// 	background-color: #303030 !important;
	// 	border-color: $header-bgcolor;

	// }
	.table {
		tbody {
			tr {
				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}
			}
		}

		tfoot {
			tr {
				td {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}

				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid map.get($darktheme-colors, "bordercolor");
					border-color: map.get($darktheme-colors, "bordercolor");
				}
			}
		}

		thead {
			tr {
				td {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}

				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}
			}
		}
	}

	.btn-primary {
		color: $primary-color;
	}

	.form-control {
		background: transparent !important;
		border: 1px solid $header-bgcolor;
		color: $primary-color;
	}

	.form-group label {
		color: $primary-color;
	}

	.iom-button {
		color: $primary-color;
	}

	.input-group-text {
		color: $primary-color;
		text-align: center;
		white-space: nowrap;
		border: 1px solid #171819;
		background-color: #171819;
	}

	.ui-datepicker {
		background: $content-bg-color !important;
		border-color: $header-bgcolor !important;

		.ui-datepicker-header {
			background-color: $content-bg-color;
		}

		select.ui-datepicker-month {
			border-color: #171717;
			background: $content-bg-color !important;
		}

		select.ui-datepicker-year {
			border-color: #171717;
			background: $content-bg-color !important;
		}

		th {
			color: $primary-color !important;
		}

		td {
			.ui-state-active {
				background: #707070;
			}
		}
	}

	.ui-datepicker-header {
		a {
			color: $primary-color !important;
		}
	}

	.ui-datepicker-month {
		color: $primary-color !important;
	}

	.ui-datepicker-year {
		color: $primary-color !important;
	}

	.ui-datepicker-prev {
		&:before {
			background: $content-bg-color !important;
		}
	}

	.ui-datepicker-next {
		&:before {
			background: $content-bg-color !important;
		}
	}


	.table-hover tbody tr:hover {
		color: $primary-color !important;
		background-color: #7c7b7b;
	}

	.smallLang {
		display: block !important;

		.dropdown-toggle::after {
			color: $fontcommon-color !important;
		}

		.header-dropdown-list {
			.dropdown-toggle {
				.language-text {
					color: #212529 !important;
				}
			}

			.dropdown-menu {
				li {
					a {
						&:hover {
							background-color: map.get($whitetheme-colors, "bgcolor") !important;
						}
					}
				}

				.active {
					a {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}

				background-color: $primary-color !important;
				border: 1px solid rgba(0, 0, 0, .15) !important;

				.dropdown-item {
					color: #212529 !important;
				}
			}
		}

	}

	.modal-body {
		background-color: $widget-bgcolor !important;
	}

	.modal-content {
		background-color: $widget-bgcolor !important;
		border: 1px solid #333;
		border-radius: 10px;
		color: $primary-color;
		width: 800px;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;
		border-top: none;
	}

	.btnModalSave {
		float: right;
		background-color: $content-bg-color;
		border: none;
		// color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $commonbase-color;
	}

	.btnModalClose {
		float: left;
		background-color: $content-bg-color;
		border: none;
		// color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $commonbase-color !important;
	}

	.successDiv {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successDiv-popup {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successDiv-popup-accept {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.theme-content-color {
		color: $primary-color;
	}

	.MessageBoxButtonSection button {
		color: $primary-color;
	}

	.cancellabel {
		border: 1px solid $header-bgcolor;
		background-color: $content-bg-color;
	}

	.joyride-step__holder {
		background-color: #171717 !important;
		box-shadow: 0 0 0px 1px #171717 !important;

		.joyride-step__container {
			background-color: #171717 !important;
			border: 1px solid $header-bgcolor !important;
			box-shadow: 0 0 0px 1px #171717 !important;
		}

		.joyride-arrow__top {
			border-bottom: 11px solid $header-bgcolor !important;
		}

		.joyride-arrow__right {
			border-left: 11px solid $header-bgcolor !important;
		}

		.joyride-arrow__left {
			border-right: 11px solid $header-bgcolor !important;
		}

		.joyride-arrow__bottom {
			border-top: 11px solid $header-bgcolor !important;
		}

		.joyride-button {
			color: $primary-color !important;

			&:hover {
				background-color: $commonbase-color !important;
				color: $secondaryFocus !important;
			}
		}

		.joyride-step__title,
		.joyride-step__body {
			color: $primary-color !important;
		}

		textarea.in {
			color: $primary-color;
			background-color: $secondaryFocus;
			border: 1px solid map.get($darktheme-colors, "bordercolor");
		}

		.tour-title-input {
			color: $primary-color;
			background-clip: padding-box;
			background-color: $secondaryFocus;
			border: 1px solid map.get($darktheme-colors, "bordercolor");
		}
	}

	.idTooltip {
		background: $fontcommon-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}

	.netComID {
		color: $primary-color;
	}

	/*------------------------- Drop-down Menu in header -------------------------*/
	.dropdown-list {
		.dropdown-list-items-contents {
			background-color: map.get($darktheme-colors, "background-color") !important;
			border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		}
	}

	.dropdown-list-row {
		color: $primary-color;

		.list-group-item {
			background-color: transparent !important;

			&:hover {
				background: $commonbase-color !important;
				color: $secondaryFocus !important;
			}
		}
	}

	/*------------------------- Drop-down Menu in header -------------------------*/

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		color: $primary-color;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link {
		color: $primary-color;
	}



	.subscriberSelect {
		.ng-select-container {
			background: $content-bg-color !important;
			border: 1px solid $header-bgcolor !important;
			color: $primary-color;
			font-size: 12px;
			height: 45px;
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
			background-color: $content-bg-color !important;
			border: 1px solid $header-bgcolor !important;

		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
			background-color: $content-bg-color !important;
			color: $primary-color;
			font-size: 13px !important;
		}
	}

	#editMode {
		select:not([multiple]) option {
			background: $content-bg-color;
		}
	}

	.spinner-circle-swish {
		color: $primary-color !important;
	}

	.form-group {
		label {
			color: $base-color !important;
		}
	}

	#header.colorChangeTest {
		.header-btn {
			background-color: transparent;
			box-shadow: none !important;

			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.dropdown-icon-menu {
			&:hover {
				.header-btn {
					background-color: $table-bgcolor;
				}
			}
		}

	}
	.checkbox {
        color: #005397;
    }


	// *********************Ebill css********************************************

	.card-header {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.accordianClass {
		color: $primary-color;
	}

	#wizard {
		background-color: map.get($darktheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($darktheme-colors, "textcolor");

		.line-1 {
			background: #666666;
		}

		.wizard-items {
			border: 1px solid $header-bgcolor;
			background: $content-bg-color 0% 0% no-repeat padding-box;

			&:hover {
				background: #171819 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}

		b.badges {
			border: 1px solid $content-bg-color;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}

	}

	#wizard .wizard-items .wizardDiv {
		border: 1px solid #404040;
		background: #404040 0% 0% no-repeat padding-box;
	}

	#wizard b.badges {
		border: 1px solid $content-bg-color;
		box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
	}

	#wizard .wizard-billeritem {
		border: 1px solid $header-bgcolor;
		background: $content-bg-color 0% 0% no-repeat padding-box;
	}

	.docLbl {
		width: 100%;
		color: $primary-color;
		font-size: 14px;
	}

	.form-control {
		color: $primary-color !important;
		background-color: $content-bg-color !important;
	}

	.docInput {
		width: 100%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #0C1214 !important;
		border: unset !important;
	}

	.common_wizardItemContainer .wizard_format {
		width: 50% !important;
	}

	.card-header {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.card {
		background-color: $content-bg-color;
		border: 1px solid rgba(0, 0, 0, 0.08);
	}

	#wizard .line-1 {
		// background: #666666 !important;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		label {
			color: $primary-color !important;
		}

		.form-control {
			// background: transparent!important;
			border: 1px solid $header-bgcolor;
			color: $primary-color;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 14px;
		}
	}

	.billTabSection {
		.stepsinout-form.formly {
			.form-control {
				color: $primary-color !important;
				background-color: $secondaryFocus !important;
				border-color: $secondaryFocus;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
				border-top-right-radius: 10px !important;
				// border-radius: 10px !important;
				-webkit-transition: border 0s ease-in-out 0s;
				-moz-transition: border 0s ease-in-out 0s;
				-o-transition: border 0s ease-in-out 0s;
				-ms-transition: border 0s ease-in-out 0s;
				transition: border 0s ease-in-out 0s;
			}
		}
	}

	.billTabSection {
		border: 1px solid $header-bgcolor;
	}

	.biller_tabSection {
		.nav-item {

			// border-right: unset !important;
			a {
				margin-right: unset !important;
				background-color: unset !important;
				color: $primary-color !important;
				border-bottom: unset !important;
				border-left-color: $header-bgcolor !important;
				border-right-color: $header-bgcolor !important;
				border-top-color: $header-bgcolor !important;
			}
		}


	}

	.btn-primary {
		background: transparent !important;
		border: 2px solid $commonbase-color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $commonbase-color !important;
			color: $common-color !important;
			border: 2px solid $commonbase-color;
		}
	}

	table.positionTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
	}

	#wizard .wizard-companyitem {
		border: 1px solid $header-bgcolor;
		background: $content-bg-color 0% 0% no-repeat padding-box;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: $fontcommon-color !important;
		border: 1px solid $header-bgcolor !important;
	}

	.companySelect .ng-select-container {
		color: $primary-color !important;
		height: 42px !important;
		border: none;

		input {
			color: $primary-color !important;
		}
	}

	#wizard {

		color: $primary-color;
	}

	#wizard {
		background-color: map.get($darktheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($darktheme-colors, "textcolor");

		.line {
			.line-1 {
				background: #666666;
			}
		}

		.line11 {
			.line-1 {
				background: transparent;
			}
		}

		.wizard-items {
			border: 1px solid $header-bgcolor;
			background: $content-bg-color 0% 0% no-repeat padding-box;

			&:hover {
				background: #171819 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}

		b.badges {
			border: 1px solid $content-bg-color;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}

		.wizard-billeritem {
			border: 1px solid $header-bgcolor;
			background: $content-bg-color 0% 0% no-repeat padding-box;

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}

	}

	// *********************end Ebill css********************************************
	.setselectHeight,
	.manageHeader {
		color: $primary-color;
	}

	#overlay,
	#overlay1,
	#overlay2,
	#overlay3 {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.comapnyLbl {
		color: $primary-color !important;
	}

	.nav_btn {
		color: $primary-color !important;

		&:hover {
			color: #202427 !important;
			background: $commonbase-color !important;
		}
	}

	.nav-tabs>li.active>a.display-border,
	.nav-tabs>li.active>a.display-border:focus,
	.nav-tabs>li.active>a.display-border:hover {
		background-color: #171717 !important;
		border-top: 2px solid $commonbase-color !important;
		border-left: 2px solid $commonbase-color !important;
		border-right: 2px solid $commonbase-color !important;
		color: $primary-color;
		box-shadow: none;
	}

	.interface-popup.nav-tabs>li {
		border-top: 2px solid $commonbase-color !important;
		border-left: 2px solid $commonbase-color !important;
		border-right: 2px solid $commonbase-color !important;
		background-color: #171717 !important;

		a {
			background-color: transparent;
		}
	}

	.interface-popup.nav-tabs>li.active>a {
		border: none !important;
		background: transparent !important;
	}

	.nav-tabs.invoice-tab {
		>li {
			border: 1px solid map.get($darktheme-colors, "bordercolor");
			border-top: none !important;

			&:hover {
				border: 1px solid #7c7b7b !important;
				border-top: none !important;
				background-color: #7c7b7b !important;
				box-shadow: 0 -2px 0 #7c7b7b !important;
			}

			>a.invoice-tabhead {
				color: $primary-color !important;
			}

			>a {
				&:hover {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}

				&:focus {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}
			}
		}

		>li.active {
			border: none !important;
			box-shadow: 0 -2px 0 $commonbase-color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: $content-bg-color !important;
				color: $primary-color !important;

				&:focus {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}

				&:hover {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}
			}
		}
	}

	.invoice-tab-header {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
		background-color: $content-bg-color !important;
		color: $primary-color !important;
	}

	.tooltipA .tooltiptext {
		background: $widget-bgcolor 0% 0% no-repeat padding-box;
		color: $base-color;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.headerContents {
		// padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		// margin-left: 0 3px !important;
		overflow: hidden;
		background: $content-bg-color;
	}

	.algntblTranscation {
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		// margin: 0 3px !important;
		overflow: hidden;
		background: $content-bg-color !important;
	}

	.commonBorderColor {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
	}

	table.dataTable {
		tbody {
			tr.selected {
				background-color: $commonbase-color !important;
				border-color: $light-border;
				color: $common-color !important;

				&:hover {
					background-color: $commonbase-color !important;
					border-color: $light-border;
				}
			}
		}
	}

	.borderHead {
		border-radius: 8px;
		padding: 1rem;
		border: 1px solid $header-bgcolor !important;
	}

	.headerContent {
		border-radius: 10px;
		border: 1px solid $header-bgcolor;
		background-color: $content-bg-color !important;
	}

	.filter-top {
		color: $primary-color !important;
	}

	.setwhiteFont {
		color: $primary-color;
	}

	.ediLbl {
		color: $primary-color;
	}

	.app-tut.active {
		background-color: #7c7b7b !important;
	}

	.ordr-invc-div.active {
		background-color: $commonbase-color !important;

		.ordr-invc-img {
			img {
				filter: brightness(0) invert(0) !important;
			}
		}

		.ordr-invc-title {
			color: $fontcommon-color !important;
		}
	}

	.snd-rcv-div.active {
		background-color: $commonbase-color !important;

		.snd-rcv-img {
			img {
				filter: brightness(0) invert(0) !important;
			}
		}

		.snd-rcv-title {
			color: $fontcommon-color !important;
		}
	}

	.app-tut {
		background-color: $content-bg-color;

		&:not(.nohover) {
			&:hover {
				background-color: #7c7b7b !important;
			}
		}
	}

	.snd-rcv-div {
		// background-color: $content-bg-color;
		border: 1px solid $commonbase-color !important;

		&:hover {
			background-color: $commonbase-color !important;

			.snd-rcv-img {
				img {
					filter: brightness(0) invert(0) !important;
				}
			}

			.snd-rcv-title {
				color: $fontcommon-color !important;
			}
		}
	}

	.ordr-invc-div {
		// background-color: $content-bg-color;
		border: 1px solid $commonbase-color !important;

		&:not(.nohover) {
			&:hover {
				background-color: $commonbase-color !important;

				.ordr-invc-img {
					img {
						filter: brightness(0) invert(0) !important;
					}
				}

				.ordr-invc-title {
					color: $fontcommon-color !important;
				}
			}
		}
	}

	.successcmpimgDiv {
		background-color: $secondaryFocus;
	}

	.snd-rcv-img {
		img {
			filter: brightness(0) invert(1);
		}
	}

	.ordr-invc-img {
		img {
			filter: brightness(0) invert(1);
		}
	}

	hr.search-hr {
		border-top: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	/* CSS for edi dashboard */
	#first-row {
		.setup-info {
			.stepper {
				.step-items {
					.step-d {
						background-color: $commonbase-color;
						opacity: 0.5;
					}

					.process-info {
						.step-title {
							border: 3px solid #333333;
							background-color: #202124;
						}

						.step-connector::before {
							border: 1px solid $header-bgcolor;
						}

						.step-connector::after {
							border: 1px solid $header-bgcolor;
						}
					}
				}

				.step-items.preceedings {
					.step-d {
						background-color: $commonbase-color;
						opacity: 1;
					}

					.step-title {
						background-color: $commonbase-color !important;
						border: 3px solid $commonbase-color !important;
					}

					.step-connector::before {
						border: 1px solid $commonbase-color !important;
					}

					.step-connector::after {
						border: 1px solid $commonbase-color !important;
					}
				}

				.step-items.active {
					.step-d {
						opacity: 1 !important;
					}

					.step-title {
						background-color: #202124;
						border: 3px solid $commonbase-color !important;
					}
					.step-title.done-process{
						// background-color: $commonbase-color !important;
					}

					.step-connector::before {
						border: 1px solid $commonbase-color !important;
					}

					.step-connector::after {
						border: 1px solid $header-bgcolor;
					}
					.step-connector.last-connector {
						&::after {
							// border: 1px solid $commonbase-color;
						}
						+ {
							div.step-title {
								// background-color: $commonbase-color !important;
							}
						}
					}
				}

			}
		}
	}
	.confirmebillLabel{
		color: #fff;
    }
    .checkmark {
		border: 2px solid $primary-color;
	}
    .checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid $primary-color;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	
    /* CSS for edi dashboard */
    
	.iom_btn,
.ebill_btn {
	color: $primary-color;
	&:hover {
		color: $common-color !important;
	}
}
.ac_ConnectionSection{
	.ac_div{
		color: $primary-color ;
	}
	.ac_typeDiv{
		color: $primary-color ;
		.common_detail{
			color: $commonbase-color;
		}
	}
	.fillGreen{
	color: #000!important;
	}

}
.footer-menu {
	color: $primary-color !important
}
.company-label {
    color: $primary-color;
}
.module-name {
	color: $primary-color;
}
.footer-menu {
	color: $primary-color !important
}
.chatIcon {
	filter: brightness(0) invert(1);
}
.fillGreen{
    color: #000!important;
    }
}
/*
---------------------------------------------------------------------------------------
    End   Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    White theme style
---------------------------------------------------------------------------------------
*/


.smart-style-2 {

	.applicationHead {
		height: 34px;
		background: $primary-color 0% 0% no-repeat padding-box;
		color: black !important;
		opacity: 1;
		border: unset;
		margin-left: 5px;
		margin-right: -5px;
	}

	#left-panel {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 0px !important;
		padding-top: 10px !important;

		nav {
			ul {
				li {
					border-bottom: 1px solid $commonbase-color;

					&:hover {
						background: $commonbase-color !important;
						color: $primary-color !important;
						border: 1px solid #3B3B3B33;
					}
				}

				li.active {
					background-color: $commonbase-color !important;

					a {
						color: #fff !important;
					}

					ul {
						li {
							color: #202427 !important;

							&:hover {
								color: $primary-color !important;
								a {
									color: $primary-color !important;
									background: $commonbase-color !important;
								}
							}

							a {
								color: $light-nav-font-color !important;
							}

						}

						li.active {
							a {
								color: $primary-color !important;
							}
						}
					}
				}

				li.open {
					li:hover {
						a {
							color: $primary-color !important;
						}
					}
				}
			}
		}
	}

	nav {
		ul {
			li.active {
				a {
					font-weight: 400;

					&:before {
						color: #202427;
					}
				}
			}
		}

		ul {
			li {
				a {
					color: $light-nav-font-color !important;
					padding: 12px 17px 12px 16px !important;
					font-size: 16px !important;
					&:focus,
					&:hover {
						color: $primary-color !important;
						background: $commonbase-color !important;
					}
					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}

			li.open {
				a {
					color: $light-nav-font-color !important;
					font-weight: 400;
				}

				&:focus,
				&:hover {
					color: $primary-color !important;
					background: $commonbase-color !important;
				}
			}

			li.open a b {
				color: $light-nav-font-color !important;
			}

			li.open.active a b {
				color: $light-nav-font-color !important;
			}

			li.open:hover>a {
				color: $primary-color !important;
			}

			li.open:hover>a b {
				color: $primary-color !important;
			}
		}
	}

	nav ul ul {
		li {
			background-color: #dbdbdb;

			a {
				padding-left: 43px !important;
				padding-top: 6px !important;
				padding-bottom: 6px !important;
				color: $primary-color;
				font-size: 14px !important;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $commonbase-color !important;
				}
			}
		}
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	.notification-body {
		.unread {
			background-color: map.get($whitetheme-colors, "bgcolor");
		}

		.from {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.msg-body {
			color: $subtext-color !important;
		}

		.activityTime {
			color: $subtext-color !important;
		}

		li {
			span {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			}
		}
	}

	.ajax-dropdown {
		border: 1px solid #d9d9d9 !important;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/light-theme/Path222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/light-theme/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/light-theme/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/light-theme/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}
		}

		.header-btn {
			box-shadow: none;
		}

		.header-btn-list {
			a {
				box-shadow: none;
			}
		}

		.menu-item {
			&:not(:last-child) {
				border: unset !important;
			}

			.webComponetsBorder {
				border: none;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}

			.webComponetsHoverColor {
				&:hover {
					background-color: map.get($whitetheme-colors, "bordercolor");
				}
			}
		}

		.menu-item.no-hover {
			&:hover {
				background-color: map.get($whitetheme-colors, "bgcolor");
			}
		}
	}

	.header-dropdown-list {
		a.dropdown-toggle {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}
			}

			.active {
				a {
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
				}
			}
		}
	}

	.theme-reverse-text-color {
		color: $secondaryFocus !important;
		color: $primary-color !important;
	}

	.theme-icon {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-header-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-subtext-color {
		color: $subtext-color !important;
	}

	.dashboard {
		.jarviswidget {
			div {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
				border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}

	#ribbon {
		.breadcrumb {
			li {
				color: map.get($whitetheme-colors, "panel-color");

				&:last-child {
					color: map.get($whitetheme-colors, "color") !important;
				}
			}
		}
	}

	.border {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;
	}

	.jarviswidget-color-darken {
		header {
			// background: map.get($whitetheme-colors, "popup-bg-color") !important;
			background: $primary-color 0% 0% no-repeat padding-box !important;
			border: none;
		}
	}

	.theme-bg-color {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.footerTextColor {
		color: $primary-color !important;
	}

	.login-info {
		color: #212529 !important;
	}

	#content {
		background: $primary-color !important;
	}

	.menuIconsOption {
		img {
			filter: brightness(100) invert(1);
		}
	}

	.menuSelected {
		background-color: #666666 !important;
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	.dropdown-icon-menu>ul>li .btn,
	.header-btn {
		background: none;
	}

	.jarviswidget>div {

		border-right-color: $primary-color !important;
		border-bottom-color: $primary-color !important;
		border-left-color: $primary-color !important;

	}

	.editButton {
		font-size: 37px;
		text-align: center;
		font: normal normal normal 30px/41px Open Sans;
		letter-spacing: -0.3px;
		background-color: $primary-color;
		border: 2px solid $commonbase-color;
		color: #202528;
		border-radius: 19px;
		font-size: 13px;
		top: 55px;
		position: relative;
		float: right;
		width: 155px;
		height: 34px;
		right: 220px;

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.commonHead {
		background: $primary-color !important;
		color: #0c1214 !important;
	}

	.headData {
		background-color: $primary-color;
		color: $light-nav-font-color;
		margin-top: 10px;
	}

	.pagination>.active>a {
		background-color: $commonbase-color !important;
		border-color: $commonbase-color !important;
		color: $primary-color;
	}

	.pagination>li>a {
		background-color: $primary-color !important;
		color: $common-color;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		background-color: $primary-color !important;
		// color: $commonbase-color;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: $primary-color !important;
		// color: $commonbase-color;

	}

	.dt-toolbar-footer {
		background: none !important;
		font-size: none !important;
		overflow: hidden;
		padding: none !important;
		border-top: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		-ms-box-shadow: none !important;
		box-shadow: none !important;
		width: 100%;
	}

	table.positionTable {
		border: 1px solid $light-border !important;
		margin-top: 0px !important;
		border-radius: 5px;
	}

	.btn-primary {
		color: $content-bg-color !important;
		border-color: $commonbase-color !important;
	}

	.form-control {
		background-color: transparent;
		border: 1px solid $light-border;
		color: $fontcommon-color;
	}

	.iom-button {
		color: $fontcommon-color;
	}

	.input-group-text {

		color: $fontcommon-color;
		border: 1px solid #EEEEEE;
		border-radius: 0.25rem;
		background-color: #EEEEEE;

	}

	.ui-datepicker {
		td {
			.ui-state-highlight {
				color: $primary-color;
			}

			.ui-state-default {
				color: $tabsborder-color;
			}

			.ui-state-hover {
				color: $primary-color;
			}

			.ui-datepicker-header {
				background-color: $primary-color;
			}
		}
	}


	.table-hover tbody tr:not(.selected):hover {
		color: $common-color !important;
		background-color: #ededed !important;
	}

	table.dataTable {
		tbody {
			tr.selected {
				background-color: $commonbase-color !important;
				border-color: $light-border;
				color: $primary-color !important;

				&:hover {
					background-color: $commonbase-color !important;
					border-color: $light-border;
				}
			}
		}
	}

	.darkHeaderIcon,
	.lightHeaderIcon {
		color: $common-color !important;
	}

	.editPaymentHeader {
		color: $common-color !important;
		font-size: 16px !important;
	}

	.payContent {
		background: #F7F7F7 !important;
	}

	.btnModalSave {
		float: right;
		background-color: $primary-color;
		border: none;
		// color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $commonbase-color;
	}

	.btnModalClose {
		float: left;
		background-color: $primary-color;
		border: none;
		// color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $commonbase-color !important;
	}

	.exportBtn-help h1,
	.exportBtn-help-table h1 {
		color: #2c2c2c !important
	}

	.searchTableContacts.exportBtn-help,
	.searchTable.exportBtn-help,
	.powerd-by .exportBtn-help,
	.downloadCenter .exportBtn-help,
	.exportBtn-help-table,
	.downloadCenter .exportBtn-help-download {
		color: #2c2c2c !important
	}

	.searchTableContacts.exportBtn-help:before,
	.searchTable.exportBtn-help:before {
		border-right-color: $primary-color;
	}

	.successDiv {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.successDiv-popup {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.successDiv-popup-accept {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.theme-content-color {
		color: #666666;
	}

	.MessageBoxButtonSection button {
		color: $common-color !important;
	}

	.cancellabel {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		background-color: $primary-color;
	}

	textarea.in {
		color: $common-color !important;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.tour-title-input {
		color: $common-color !important;
		background-clip: padding-box;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.joyride-step__holder {
		background-color: map.get($whitetheme-colors, "bgcolor") !important;
		box-shadow: 0 0 0px 1px map.get($whitetheme-colors, "bgcolor") !important;

		.joyride-step__container {
			background-color: map.get($whitetheme-colors, "bgcolor") !important;
			box-shadow: 0 0 0px 1px map.get($whitetheme-colors, "bgcolor") !important;
		}

		.joyride-arrow__top {
			border-bottom: 11px solid map.get($whitetheme-colors, "bgcolor") !important;
		}

		.joyride-button {
			color: $secondaryFocus !important;

			&:hover {
				background-color: $commonbase-color !important;
				color: $secondaryFocus !important;
			}
		}

		.joyride-step__title,
		.joyride-step__body {
			color: map.get($whitetheme-colors, "color") !important;
		}
	}

	.btn-primary {
		&:not(:disabled) {
			&:not(.disabled).active {
				&:focus {
					box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
				}
			}

			&:not(.disabled) {
				&:active {
					&:focus {
						box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
					}
				}
			}
		}
	}

	.idTooltip {
		background: $primary-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");

	}

	.netComID {
		color: $fontcommon-color;
	}

	.dropdown-list {
		.dropdown-list-items-contents {
			background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.dropdown-list-row {
		color: $secondaryFocus;

		.list-group-item {
			background-color: transparent !important;

			&:hover {
				background: $commonbase-color !important;
				color: $secondaryFocus !important;
			}
		}
	}

	.billerDetailSection {

		// .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
		// 	color: $fontcommon-color;
		// 	font-size: 14px;
		// }
		.nav-tabs>li>a {
			color: $fontcommon-color !important;
		}
	}

	.companySelect {
		.ng-select-container {
			background: $fontbackground-color !important;
			border: 1px solid $header-bgcolor;
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
			background-color: #f5faff !important;
			border: none !important;
			color: $fontcommon-color !important;
			font-size: 13px !important;

		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
			background-color: transparent !important;
			color: $fontcommon-color !important;
			// border: 1px solid #6666 !important;	
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
			background-color: #ebf5ff !important;
		}

	}

	.topMenuLeft {
		background-color: #CCCC;
	}

	.topMenuLeft:hover {
		background-color: #6666;
	}

	.iom_btn,
	.ebill_btn {
		color: #171717;
		&:hover {
			color: #fff !important;
		}
	}

	.ebillForm {
		.form-control {
			background-color: $fontbackground-color !important;
		}

		formly-field-select {
			select {
				background-color: $fontbackground-color !important;

				option {
					background-color: $fontbackground-color !important;
				}
			}
		}
	}

	// .tokenForm {
	.form-control {
		background-color: transparent !important;
	}

	select {
		background-color: $fontbackground-color !important;

		option {
			background-color: $fontbackground-color !important;
		}
	}

	// }

	.subscriberSelect {
		.ng-select-container {
			background: #EEEEEE !important;
			font-size: 12px;
			border-radius: 10px !important;
			height: 45px;
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
			background-color: #EEEEEE !important;
			font-size: 13px !important;
		}
	}

	.successdeleteDiv {
		background-color: $fontbackground-color !important;
	}

	.fa-refresh {
		color: #333;
	}

	.lblWhite {
		color: $secondaryFocus !important;
	}

	.lstLbl {
		color: $secondaryFocus !important;
	}

	.detailLbl {
		color: $secondaryFocus !important;
	}

	.spinner-circle-swish {
		color: $secondaryFocus !important;
	}

	.form-group {
		label {
			color: $secondary-color !important;
		}
	}

	#header.colorChangeTest .topMenuItem {
		color: #666666 !important;
	}

	#header.colorChangeTest .topMenuLeft {
		color: #666666 !important;
		background-color: transparent;
		// border: 1px solid #CCCC;
	}

	#header.colorChangeTest .topMenuLeft:hover {
		background-color: $light-border;

		.topMenuItem {
			color: $primary-color !important;
		}
	}

	#header.colorChangeTest #logo {
		filter: contrast(0%);
	}

	#header.colorChangeTest .menuSelected .topMenuItem {
		color: $primary-color !important;
	}

	#header.colorChangeTest .topMenuLeft.menuSelected:hover {
		background-color: transparent;
	}

	// *********************Ebill css********************************************
	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		formly-field-select select option {
			background-color: unset !important;
		}

		.form-control {
			background: $primary-color !important;
			border: 1px solid $header-bgcolor !important;
			color: $fontcommon-color;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 14px;
		}

	}

	#wizard .wizard-items {
		border: 1px solid $light-border;
		background: $primary-color 0% 0% no-repeat padding-box;
	}

	#wizard .wizard-billeritem {
		border: 1px solid $light-border;
		background: $primary-color 0% 0% no-repeat padding-box;
	}

	#wizard .wizard-items .wizardDiv {
		border: 1px solid transparent;
		background: $light-border 0% 0% no-repeat padding-box;
	}

	.form-control:disabled {
		background-color: #EEEEEE !important;
	}

	.docInput {
		width: 100%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important;
	}

	#wizard .wizard-companyitem {
		border: 1px solid $light-border;
		background: $primary-color 0% 0% no-repeat padding-box;
	}

	//           .wizardcmp_format {
	// 	width: 20% !important;
	// 	border-radius: 10px !important;
	//           }
	.docLbl {
		width: 100%;
		color: $fontcommon-color;
		font-size: 14px;
	}

	#wizard .line-1 {
		background: $light-border;
	}

	.billTabSection {
		border: 1px solid #ced4da;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		formly-field-select select option {
			background-color: unset !important;
		}

		.form-control {
			background: $primary-color !important;
			border: 1px solid $light-border !important;
			color: $fontcommon-color;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 14px;
		}

	}

	#wizard {
		color: #666666;
	}

	.docInput {
		width: 100%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important;
	}

	.btn-primary {
		background: $primary-color;
		border: 2px solid $commonbase-color;
		color: $content-bg-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;
		&:hover {
			color: $primary-color !important;
		}
	}

	.common_wizardItemContainer .wizard_format {
		width: 55% !important;
	}

	.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		font-size: 13px !important;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: $fontcommon-color !important;
		border: 1px solid #cccccc7a !important;
	}

	.companySelect .ng-select-container {
		border-radius: 10px !important;
		color: $fontcommon-color !important;
		height: 42px !important;
		border: none;
		font-size: 12px !important;
	}

	.nav-tabs li {
		float: left;
		margin-bottom: -1px;
		// width: 34%;
		text-align: center;
		border-bottom: 1px solid $light-border !important;
	}

	.biller_tabSection .nav-item {
		width: auto !important;
	}

	.biller_tabSection .nav-item a {
		margin-right: unset !important;
		background-color: unset !important;
		color: $fontcommon-color !important;
		border-bottom: unset !important;
		border-left-color: #6666 !important;
		border-right-color: #6666 !important;
		border-top-color: $header-bgcolor !important;
	}

	#wizard {
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($whitetheme-colors, "textcolor");

		.line {
			.line-1 {
				background: $light-border;
			}
		}

		.line11 {
			.line-1 {
				background: transparent;
			}
		}

		.wizard-items {
			border: 1px solid $light-border;
			background: $primary-color 0% 0% no-repeat padding-box;

			&:hover {
				background: #ededed 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid transparent;
				background: $light-border 0% 0% no-repeat padding-box;

				span {
					color: $secondaryFocus;
				}
			}
		}

		.wizard-billeritem {
			border: 1px solid $light-border;
			background: $primary-color 0% 0% no-repeat padding-box;

			.wizardDiv {
				border: 1px solid transparent;
				background: $light-border 0% 0% no-repeat padding-box;

				span {
					color: $secondaryFocus;
				}
			}
		}

		b.badges {
			border: 1px solid $primary-color;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}
	}

	.common_wizardItemContainer {

		// .common_wizardItems{
		//              width: 20% !important;
		// width: 35% !important;
		// }
		.wizard_format {
			width: 50% !important;
		}
	}

	// *********************end Ebill css********************************************
	.setselectHeight,
	.businessLbl {
		color: $fontcommon-color !important;
	}

	#overlay,
	#overlay1,
	#overlay2,
	#overlay3 {
		background: #F7F7F7 (0, 0, 0, 0.5);
	}

	.comapnyLbl {
		color: $fontcommon-color !important;
	}

	.nav_btn {
		color: $light-nav-font-color !important;
		&:hover {
			color: $primary-color !important;
			background: $commonbase-color !important;
			.fa-refresh {
				color: $primary-color !important;
			}
		}
		.fa-refresh {
			color: $common-color !important;
			&:hover {
				color: $primary-color !important;
			}
		}

		
	}

	.headerContents {
		// padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $light-border !important;
		// margin-left: 0 3px !important;
		overflow: hidden;
	}

	.algntblTranscation {
		padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $light-border;
		margin: 0 3px !important;
		overflow: hidden;
	}

	.selected {
		background-color: $commonbase-color !important;
		border-color: $commonbase-color !important;

		&:hover {
			color: $common-color !important;
		}
	}

	.commonBorderColor {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.nav-tabs>li.active>a.display-border,
	.nav-tabs>li.active>a.display-border:focus,
	.nav-tabs>li.active>a.display-border:hover {
		background-color: $primary-color !important;
		border-top: 2px solid $commonbase-color !important;
		border-left: 2px solid $commonbase-color !important;
		border-right: 2px solid $commonbase-color !important;
		color: $fontcommon-color;
	}

	.nav-tabs.invoice-tab {
		>li.active {
			border-bottom: none !important;
			box-shadow: 0 -2px 0 $commonbase-color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: transparent !important;
				color: $common-color !important;

				&:focus {
					background-color: $primary-color !important;
					color: $common-color !important;
				}

				&:hover {
					background-color: $primary-color !important;
					color: $common-color !important;
				}
			}
		}

		>li {
			border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			border-right: 1px solid map.get($whitetheme-colors, "bordercolor") !important;

			&:last-child {
				border-right: none !important;
			}

			&:hover {
				border-bottom: 1px solid $light-border !important;
				border-top: none !important;
				background-color: $light-border !important;
			}
		}

		>li {
			>a {

				// box-shadow: 0 -2px 0 map.get($whitetheme-colors, "bordercolor") !important;
				&:hover {
					color: $common-color !important;
				}
			}
		}
	}

	.invoice-tab-header {
		border: 1px solid map.get($whitetheme-colors, "bgcolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
	}

	.setCircleTest {
		color: #F1DA91;
		font-size: 10px !important;
	}

	.tooltipA .tooltiptext {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		color: map.get($whitetheme-colors, "color");
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
	}

	.jarviswidget {
		header {
			border: none !important;
		}
	}

	.borderHead {
		border-radius: 8px;
		padding: 1rem;
		border: 1px solid $light-border !important;
	}

	.headerContent {
		border-radius: 10px;
		border: 1px solid $light-border;
	}

	.app-tut {
		background-color: #ededed !important;
	}

	.app-tut.active {
		background-color: #CCC !important;
	}

	.ordr-invc-div.active {
		background-color: $commonbase-color !important;
	}

	.snd-rcv-div.active {
		background-color: $commonbase-color !important;
	}

	.app-tut {
		background-color: $content-bg-color;

		&:not(.nohover) {
			&:hover {
				background-color: #CCC !important;
			}
		}
	}

	.snd-rcv-div {
		// background-color: $content-bg-color;
		border: 1px solid $commonbase-color !important;

		&:hover {
			background-color: $commonbase-color !important;
		}
	}

	.ordr-invc-div {
		border: 1px solid $commonbase-color !important;

		// background-color: $content-bg-color;
		&:not(.nohover) {
			&:hover {
				background-color: $commonbase-color !important;
			}
		}
	}

	.step {
		border: 2px solid $light-border !important;
		background: $light-border !important;
	}

	.successcmpimgDiv {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.trapezium {
		border-bottom: 34px solid #ccc !important;
	}

	.ordr-invc-div .ordr-invc-img img {
		filter: brightness(0) invert(0) !important;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;
		border-top: none;
	}

	hr.search-hr {
		border-top: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.ordr-invc-div.disabled {
		color: #999 !important;

		.ordr-invc-img {
			img {
				opacity: 0.25 !important;
			}
		}
	}

	.setgreenFont {
		color: #474d22 !important;
	}

	//-------------------------responsive view start ------------------------

	img.infoIcon.infoIcon-color {
		// filter: brightness(0.1);
		filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
	}

	img.infoIcon {
		// filter: brightness(0.1);
		filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
	}

	.table>tbody>tr>td,
	.table>tbody>tr>th {
		border-top: 1px solid #dddddd5e !important;
	}

	//-------------------------responsive view end --------------------------

	/* CSS for edi dashboard */
	#first-row {
		.setup-info {
			.stepper {
				.step-items {
					.step-d {
						background-color: $commonbase-color;
						border: 2px solid $commonbase-color !important;
						opacity: 0.50;
					}

					span.step-number {
						color: #fff;
					}

					.process-info {
						.step-title {
							border: 3px solid $light-border;
							background-color: #ffffff;
						}

						.step-connector::before {
							border: 1px solid $light-border;
						}

						.step-connector::after {
							border: 1px solid $light-border;
						}
					}
				}

				.step-items.preceedings {
					.step-d {
						background-color: $commonbase-color;
						opacity: 1;
					}

					.step-title {
						background-color: $commonbase-color !important;
						border: 3px solid $commonbase-color !important;
					}

					.step-connector::before {
						border: 1px solid $commonbase-color !important;
					}

					.step-connector::after {
						border: 1px solid $commonbase-color !important;
					}
				}

				.step-items.active {
					.step-d {
						opacity: 1 !important;
					}

					.step-title {
						background-color: $primary-color;
						border: 3px solid $commonbase-color !important;
					}
					.step-title.done-process{
						// background-color: $commonbase-color !important;
					}

					.step-connector::before {
						border: 1px solid $commonbase-color !important;
					}

					.step-connector::after {
						border: 1px solid $light-border;
					}
					.step-connector.last-connector {
						&::after {
							// border: 1px solid $commonbase-color;
						}
						+ {
							div.step-title {
								// background-color: $commonbase-color !important;
							}
						}
					}
				}

			}
		}
	}
	.confirmebillLabel{
		color: #000;
	}
	.checkmark {
		border: 2px solid #666666
	}

	
	.checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid #959595;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	/* CSS for edi dashboard */
	.ac_ConnectionSection{
		.ac_div{
			color: $light-nav-font-color;
		}
		.ac_typeDiv{
			color: $light-nav-font-color;
			.common_detail{
				color: $commonbase-color;
			}
		}
		.fillGreen{
		color: $primary-color !important;
		}

	}
	.company-label {
		color: $light-nav-font-color;
	}
	.iom_btn,
	.ebill_btn {
		color: #000;
		&:hover {
			color: $primary-color !important;
		}
	}
	.module-name {
		color: $light-nav-font-color;
	}
	.footer-menu {
		color: $light-nav-font-color !important
	}
	.fillGreen{
		color: $primary-color !important;
		}
}

/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/

.invoiceListTable td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invoiceCompanyTooltip {
	background-color: black;
	color: $primary-color;
	text-align: center;
	border-radius: 6px;
	padding: 2px 2px;
	word-break: break-all;
	z-index: 1;
	white-space: normal;
	position: absolute;
	min-width: 250px;

}

.infoIcon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}



.smart-style-1 .jarviswidget>div {
	background-color: $content-bg-color !important;
	border: none;
}

.smart-style-1 .jarviswidget>div {
	border: none;
}

.jarviswidget .widget-body {
	min-height: 100px;
	position: relative;
	padding-bottom: 13px;
}

.clearfix,
.inner-spacer:after,
.jarviswidget,
.jarviswidget-editbox,
.jarviswidget>div {
	zoom: 1;
}

.jarviswidget>div {
	background-color: $primary-color !important;
}

.jarviswidget>div {
	padding: 13px 10px;
}

.greentext {
	color: $commonbase-color !important;
}

.bluetext {
	color: #006589 !important;
}

.create-contact {
	.custom-radio.custom-control {
		display: inline-block !important;
	}

	.custom-radio {
		.custom-control-label {
			margin-right: 20px;

			&::before {
				border-radius: 50%;
				margin-top: 3px;
			}
		}

		.custom-control-input {
			&:checked {
				~ {
					.custom-control-label {
						&::after {
							background-image: none !important;
						}
					}
				}
			}
		}
	}

	.custom-checkbox {
		.custom-control-label {
			&::before {
				width: 1.2rem;
				height: 1.3rem;
				top: 0.5rem;
			}

			&::after {
				top: 0.4rem;
				left: -1.7rem;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}

	.form-group {
		label {
			font-size: 14px;
		}

		textarea.form-control {
			min-height: 140px !important;
			height: auto !important;
			resize: none;
		}
	}

	.invalid-feedback {
		font-size: 14px;
	}
}

.form-control.is-invalid {
	background: none !important;
}

#header.colorChangeTest {
	background-color: #474d226e !important;
}

.panelSector .ng-select.ng-select-multiple .ng-select-container {
	.ng-value-container .ng-placeholder {
		position: relative;
		top: 0px;
	}
}

.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container {
	border: 1px solid $invalid-bordercolor !important;

	.ng-value-container .ng-placeholder {
		position: relative;
		top: 0px;
	}
}

.cookie-text {
	font-size: 12px;

}

.show-consent {
	display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

.cookie-consent {
	width: 100%;
	flex-wrap: wrap;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	color: #d6d6d6;
	background-color: #3c404d;
	padding: 0.6em 1.8em;
	z-index: 9999;
	padding: 10px 0 0 10px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.consent-btn {
	color: $fontcommon-color;
	flex: 1 0 130px;
	background-color: $commonbase-color;
	border-color: transparent;
	padding: 0.2em 0.5em;
	font-size: 1.2em;
	font-family: Helvetica, Calibri, Arial, sans-serif;
	font-weight: bolder;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap;
	border-radius: 5px;
	min-height: 20px;
	margin: 0 30px 10px 10px;
	max-width: 100px;
}

.priUrl {
	color: $commonbase-color !important;
	text-decoration: none;
	font-weight: 500;
}

a {
	color: $commonbase-color;
	text-decoration: none;
	font-weight: 500;

	&:hover {
		color: $commonbase-color;
	}
}

table thead tr th {
	font-size: 14px;
	font-weight: 400;
}

.font-size-16 {
	font-size: 16px !important;
}

.smart-style-1 .selected {
	.commonMergeBtn {
		// border: 1px solid $fontcommon-color !important;
		color: $fontcommon-color !important;
	}

	.commonMergeBtn:hover {
		border: 1px solid $fontcommon-color !important;
		color: $fontcommon-color !important;
	}
}

//-----------------------responsive view start------------------------
@media (max-width: 948px) {
	span.theme-header-text-color.language-text {
		display: none !important;
	}

	li.dropdown {
		width: 45px !important;
	}

	#logo {
		width: 100px !important;
	}

	// #header>:first-child, aside 
	// {
	// width: 181px !important;
	// }
}

@media (min-width:283px) and (max-width:332px) {
	.collapseMenu.menu-left-item {
		margin-right: 50px !important;
	}
}

@media (max-width:331px) {
	.menu-left-item.topMenuLeft.menuSelected {
		margin-left: 20px !important;
	}
}

.user-bar {
	margin-left: -10px;
	/* margin-top: 10px; */
}

div#DataTables_Table_2_wrapper {
	overflow: auto !important;
}

@media (max-width:660px) {
	.footer-menu {
		padding: 8px 4px 3px 10px !important;
		color: #ffffff !important;
	}
}

@media (max-width:805px) {
	.navFooter {
		position: initial !important;
	}
}

//-----------------------responsive view end-------------------------
.smart-style-1 .iconForm {
	color: #ffffff !important;
}

@media (max-width:670px) {
	.commonStyleFilter {
		flex: 1 0 90%;
		margin: 0px 6px;
		display: grid;
	}
}

.userDash_tileHead,
.clickLbl {
	color: $primary-color;
	font-weight: 500;
	font-size: 13px;
}

.userDash_tileHeadgreen,
.clickLblgreen {
	color: $primary-color;
	font-weight: 500;
}

.smart-style-1 .tile-formbtn {
	background-color: unset !important;
	border: 2px solid $commonbase-color !important;

	&:hover {
		background-color: $commonbase-color !important;
		// border: 1px solid $commonbase-color !important;
		color: $common-color !important;
	}
}

.smart-style-2 .tile-formbtn {
	background-color: unset !important;
	color: $primary-color !important;
	border: 2px solid $commonbase-color !important;

	&:hover {
		background-color: $commonbase-color !important;
		// border: 1px solid $commonbase-color !important;
	}
}
.smart-style-1 .lstTrncnTbl {
	thead {
	  tr {
		th {
		  background-color: #1e1e1e!important;
		  color: #fff !important;
		  border-color: #333 !important;
		}
	  }
	}
  }
  .codePopup {
	width: 1500px !important;
}
.jarviswidget{
	position: static !important;
}
.checkbox-container input:checked~.checkmark {
    background-color: transparent;
}
// .dataTables_processing.panel.panel-default {
//     z-index: -9999 !important;
//     color: #202124 !important;
//     background: linear-gradient(to right, rgb(32 33 36) 0, rgb(32 33 36) 25%, rgb(32 33 36) 75%, rgb(32 33 36) 100%) !important;
//     border: 1px solid #202124 !important;
// 	margin-left: -100px !important;
// 	margin-left: -900px !important;
// }
input.row-checkbox {
	height:17px !important;
	width:17px !important;
	accent-color: $commonbase-color !important;
  }
  .checkmark {
	position: absolute;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 7px;
	background-color: transparent;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
	background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
	display: block;
}
