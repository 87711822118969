/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
@import "commonstyle";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");
@import "~@ng-select/ng-select/themes/default.theme.css";
body:not(.menu-on-top).desktop-detected {
    min-height: auto !important;
	overflow: hidden;
}
body {
    background: unset;
    background-color: #202124;
}
#content{
	padding: 20px !important;
	max-height: calc(100vh - 100px);
    overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}
}
.page-footer {
    /* padding-left: 0px; */
    z-index: 0;
	border-top: none;
}
#logo {    
    background-repeat: no-repeat;
    background-size: 100%;
    height: 49px !important;
    width: 135px;
    display: block;
    margin-top: 0px;
	margin-left: 36px;
}
.setnavBackgroundImg{	
    position: relative;
    z-index: 2;
    max-width: none;
    width: 90px;
    height: 90px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
	height: 110px;
	width: 110px;
}
.col-xs-10 {
    width: 83.33333333% !important;
}

.col-xs-2 {
    width: 16.66666667% !important;
}

.col-xs-3 {
    width: 25% !important;
}

.col-xs-7 {
    width: 58.33333333% !important;
}

.col-xs-8 {
    width: 66.66666667% !important;
}

.col-xs-6 {
    width: 50% !important;
}

.col-xs-1 {
    width: 8.33333333% !important;
}

.col-xs-4 {
    width: 33.33333333% !important;
}

.col-xs-5 {
    width: 41.66666667% !important;
}

#extr-page {
	overflow-x: hidden;
	#header {
		#logo {
			img {
				width: 200px;
			}
			margin-left: 75px !important;
			margin-top: 10px !important;
		}
	}
}

.notification-body {
	.unread {
		background: $table-bgcolor;
	}
	li {
		span {
			background: $secondaryFocus !important;
		}
	}
}
.li-footer__copy-text {
	color: #8B91A0;
}
.registeContent{
	max-height: calc(100vh);
}
.iom-logo {
	height: 40px;
	width: 120px;
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: -6px;
}
.logoconfirmRegister{
	height: 100px;
	width: 200px;
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: -15px;
}
.loader-outer{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
  
  .loader>span {
    width: 15px;
    height: 15px;
    display: block;
    background: $commonbase-color;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot-1 {
    animation: anim 1s linear 0s infinite;
  }
  
  .dot-2 {
    animation: anim 1s linear 0.25s infinite;
  }
  
  .dot-3 {
    animation: anim 1s linear 0.50s infinite;
  }
  
  .dot-4 {
    animation: anim 1s linear 0.75s infinite;
  }
  
  @keyframes anim {
    0% {
      top: 0;
    }
  
    50% {
      top: 15px;
    }
  
    100% {
      top: 0;
    }
  }
  .dataTables_info {
	.select-item {
		padding: 5px;
	}
  }
@media (max-width: 1161px) and (min-width: 300px) {
    #extr-page #header #logo img {
        width: 150px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}

@media only screen and (max-width: 280px) {
    #header>:first-child,
    aside {
        width: 150px;
    }
    #extr-page #header #logo img {
        width: 125px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}
.includePdf label {
margin-bottom: 15px !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
background-image: none !important;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
	#logo-group {
		background: unset !important;
	}
	#logo {
		background-image: url(/assets/img/bexio-citrus.png);
	}
	.iom-logo {
		background-image: url(/assets/img/logo.svg);
	  }
	  .logoconfirmRegister{
		background-image: url(/assets/img/iomLogoWhite.svg);
	  }
	.btn-header {

			&:first-child {
				a {
					background: unset;
					border: 1px solid $border-color;
				}
		}
	}
	#header {
		background-color: #202124;
	}
	aside {
		background-color: #171819;
		border-right: 1px solid #333333 !important;
	}
	.page-footer {
		background-color: #202124;
	}
	#ribbon {
		background: $tabsborder-color;
	}
	.ajax-dropdown {
		background-color: $secondaryFocus !important;
	}
	.ajax-notifications {
		background-color: $secondaryFocus !important;
	}
	.header-dropdown-list {
		a {
			&:hover {
				color: $primary-color !important;
			}
		}
	}
	.setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group348.svg);	
	}
	.ng-select.ng-select-opened > .ng-select-container {
		background: #0C1214 !important;
	          }
	          .ng-select .ng-select-container {
		background: #0C1214;
		color: $primary-color;
		font-size: 12px;
		border-radius: 10px !important;
		border: unset !important;
	}

	.ng-select.ng-select-opened>.ng-select-container {
		background: #0C1214 !important;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: #202124 !important;
		color: #ffff;
		font-size: 12px;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		color: #ffff !important;
	}

	.ng-dropdown-panel.ng-select-bottom {
		border: 1px solid #666666 !important;
	}
	.smart-form .checkbox input:checked+i {
		border: 2px solid #666666;
		border-radius: 5px;
	}
}

/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
	#logo {
		background-image: url(/assets/img/bexio-citrus.png);
		margin-top: 2px;
	}
	.iom-logo {
		background-image: url(/assets/img/logoWhite.svg);
	  }
	  .logoconfirmRegister{
		background-image: url(/assets/img/iomLogoBlack.svg);
	  }
	.login-info {
		color: #212529;
	}
	aside{
		color: $primary-color !important;
		background: #ededed !important;
	 }
	 #header {
		 background-color: $primary-color;
	 }
	 .setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group349.svg);	
	}
	.ng-select .ng-select-container {
		background: #EEEEEE !important;
		// color: $primary-color;
		// font-size: 12px;
		border-radius: 10px !important;
		// b
		order: unset !important;
	}
	.smart-form .checkbox input:checked+i {
		border-color: #666666 !important;
	          }
}

/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/

@media (max-width: 992px) {
	#content{
		max-height: calc(100vh - 130px) !important;
	}
	// .iom-logo {
	// 	margin-top: -40px;
	// }
}
@media (max-width: 376px){
	#content{
		max-height: calc(100vh - 300px) !important;
	}	
}
body:not(.menu-on-top).mobile-detected {
    min-height: auto !important;
    overflow: hidden;
}
.checkbox i {
    position: absolute;
    top: 3px;
    left: 0;
    display: block;
    width: 17px;
    height: 17px;
    outline: 0;
    background: transparent!important;
    border: 2px solid #666;
    border-radius: 5px
}
.smart-form .checkbox input+i:after {
	color: $commonbase-color;
}
.smart-form .checkbox i {
	border-color: #BDBDBD;
	border: 2px solid #666666;
	border-radius: 5px;
}
.smart-form .checkbox:hover i{
	border-color: #666666;
}